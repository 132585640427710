/* eslint-disable no-undef, react/prop-types */
import React, { Component }  from 'react';
import { css } from 'react-emotion';
import { graphql, navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Helmet } from 'react-helmet';

import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import api from '../utils/api';

import gooseberry from '../assets/images/gooseberry-black.png';
import compass from '../assets/images/compass-button.png';

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const noTop = css`
  padding-top: 0;
  margin-top: 0;
`;

const buttonClass = css`
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const buttonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;


class UpdateDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  keys = {
    name: '',
    password: '',
    email: '',
    email_pending: '',
    email_publicity: false,
    address: '',
    postcode: '',
    wanted: false,
    sent: false,
    arrived: false,
    registered: false,
  };

  state = {
    values: {},
    message: null,
    isSubmitting: false,
  };

  componentDidMount() {
    api.refresh()
    .then(res => {
      if (res.status >= 400) {
        throw new Error('The server is down, please try later');
      }
      return (res.json());
    })
    .then((returned) => { this.setFormValues(returned.result); })
  }

  logout = () => {
    document.cookie = 'id=; Path=/api; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    location.reload();
  }

  command = null;

  preSubmit = (command) => {
    this.command = command;
    return true;
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({message: null});

    var sendValues = {};
    const form = e.target;
    Array.from(form.elements).forEach((element) => {
      if (!this.command && element.type == 'submit') {
        this.command = element.value;
      }

      if (element.name in this.keys) {
        var previousValue = this.state.values[element.name];
        if (previousValue == null) previousValue = "";

        if (element.type == 'checkbox') {
          const checkValue = +element.checked;
          if (checkValue != previousValue) {
            sendValues[element.name] = checkValue;
          }

        } else {
          if (element.value != previousValue) {
            sendValues[element.name] = element.value;
          }
        }
      }
    });

    if (this.command == 'update') {
      sendValues.command = 'register';
    } else {
      sendValues.command = this.command;
    }
    this.command = null;

    if (api[sendValues.command]) {
      api[sendValues.command](sendValues)
      .then((res) => {
        if (res.status >= 400) {
          throw new Error('The server is down, please try later');
        }
        return (res.json());
      })
      .then((returned) => {
        if (returned.message) {
          this.setState({message: returned.message});
        } else if (sendValues.command == "register") {
          this.setState({message: "Information updated"});
        } else if (sendValues.command == "resetrequest") {
          this.setState({message: "Check your email for a password reset message"});
        }

        this.setFormValues(returned.result);
      });

    } else {
      this.setState({message: "Missing command"});
    }
  };

  setFormValues = (results) => {
    var values = Object.assign({}, this.keys);
    for (const prop in this.keys) {
      if (prop in results) {
        values[prop] = results[prop];
      }
    }
    this.setState({values});

    setTimeout( () => {
      const form = document.forms[0];
      form.reset();
      Array.from(form.elements).forEach((element) => {
        if (element.name in values) {
          element.value = values[element.name];
          element.checked = !!+values[element.name];
        }
      });
    }, 0 );
  };

  render() {
    return (
    <>
        <p className={top}><img src={gooseberry} alt="Sea Gooseberry" width="200"/></p>
        <h1 className={noTop}>Your Details</h1>
        <p>If you'd like to look after a Sea Gooseberry.</p>

        <form onSubmit={this.submit}>
          { !!+this.state.values.sent &&
            <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
              <p>
                <strong>Has a Sea Gooseberry made its way to you?</strong>
                <input
                  className="form-field-checkbox"
                  type="checkbox"
                  name="arrived"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </p>
            </Flex>
          }

          <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
            <label className="form-field-label-code">
              Name:
            </label>
            <input
              className="form-field-register"
              type="text"
              name="name"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              readonly={ this.state.values.registered ? "readonly" : false }
            />
          </Flex>

          <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
            <label className="form-field-label-code">
              Password:
            </label>
            <input
              className="form-field-register"
              type="password"
              name="password"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
          </Flex>

          <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
            <label className="form-field-label-code">
              Email:
            </label>
            <input
              className="form-field-register"
              type="email"
              name="email"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              placeholder={this.state.values.email_pending}
            />
          </Flex>

          { this.state.values.email_pending > '' &&
            <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
              <p>Please check your inbox to confirm your email address.  (Check your spam folder if you can't see the email.)</p>
            </Flex>
          }

          { this.state.values.registered ?
            <>
              <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
                <p>
                  Would you like to look after a Sea Gooseberry at home and can you bring it to the Gathering in Folkestone on 19th December at 3.30pm?
                  <input
                    className="form-field-checkbox"
                    type="checkbox"
                    name="wanted"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                </p>
              </Flex>

              <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
                <label className="form-field-label-code">
                  Address:
                </label>
                <input
                  className="form-field-register"
                  type="text"
                  name="address"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </Flex>

              <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
                <label className="form-field-label-code">
                  Postcode:
                </label>
                <input
                  className="form-field-register"
                  type="text"
                  name="postcode"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </Flex>

              <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
                <p>
                  Would you like information about events by Creative Folkestone sent to your email address?
                  <input
                    className="form-field-checkbox"
                    type="checkbox"
                    name="email_publicity"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                </p>
              </Flex>

              <div className={buttonContainer}>
                <button
                  className={buttonClass}
                  type="submit"
                  name="command"
                  value="update"
                  disabled={this.state.isSubmitting}
                  onClick={() => this.preSubmit("update")}
                >
                  <img alt="update details" src={compass} width="40px"/>
                  Update
                </button>

                <button
                  className={buttonClass}
                  type="button"
                  onClick={this.logout}
                  disabled={this.state.isSubmitting}
                >
                  <img alt="logout" src={compass} width="40px"/>
                  Logout
                </button>
              </div>
            </>

          : // !this.state.values.registered
            <div className={buttonContainer}>
              <button
                className={buttonClass}
                type="submit"
                name="command"
                value="register"
                disabled={this.state.isSubmitting}
                onClick={() => this.preSubmit("register")}
              >
                <img alt="register" src={compass} width="40px"/>
                Register
              </button>

              <button
                className={buttonClass}
                type="submit"
                name="command"
                value="login"
                disabled={this.state.isSubmitting}
                onClick={() => this.preSubmit("login")}
              >
                <img alt="login" src={compass} width="40px"/>
                Login
              </button>

              <button
                className={buttonClass}
                type="submit"
                name="command"
                value="resetrequest"
                disabled={this.state.isSubmitting}
                onClick={() => this.preSubmit("resetrequest")}
              >
                <img alt="forgot password" src={compass} width="40px"/>
                <span>Forgot<br/>Password</span>
              </button>
            </div>
          }

          {
            this.state.message &&
            <p className="error-message">{this.state.message}</p>
          }

        </form>
    </>
    )
  };
};

const imgStyle = css`
  border-radius: 5px;
`;

const UpdateDetails = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>You - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
      >
      <Flex justifyContent="center" align-items="center">
        <PaperBox
          width={[1, '80%', '60%']}
          m={['3.5rem 0', '3.5rem 0', '5.5rem auto']}
          px={[3, 3, 5]}
          py={[3, 3, 3]}
          color={colors.secondary}
          textAlign="center">
          <UpdateDetailsFormComponent></UpdateDetailsFormComponent>
        </PaperBox>
      </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

export default UpdateDetails;
